<template>
  <div class="container">

    <div class="formed-pps-teaching-load mt-4 mb-4">
      <h4 class="text-center mb-4">Индивидуальная нагрузка преподавателя</h4>



      <DataTable :value="ppsLoad_form.individualPpsTeachingLoad" :paginator="true" :rows="10" showGridlines
                 paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                 :rowsPerPageOptions="[10,20,50]"
                 currentPageReportTemplate="Показано {last} из {totalRecords}"
                 stripedRows responsiveLayout="scroll">
        <Column header="Преподаватель">
          <template #body="{data}">
            {{data.lastname}} {{data.firstname}} {{data.middlename}}
          </template>
        </Column>


        <Column field="discipline_name" header="Название дисциплины"></Column>
        <Column field="education_type_name" header="Тип"></Column>
        <Column field="hour" header="Количество часов"></Column>
        <Column field="students_count" header="Количество студентов"></Column>





      </DataTable>




    </div>
  </div>
</template>


<script>

import {mapGetters, mapActions, mapMutations, mapState} from "vuex";

export default {
  name: "IndividualPpsTeachingLoad",
  data() {
    return {
      urlPpsId: this.$route.query.pps_id || 0,
    }
  },
  computed: {
    ...mapState('ppsLoad', ['ppsLoad_form']),

  },

  methods: {
    ...mapActions('ppsLoad', ['GET_PPS_TEACHING_LOAD_BY_PPS_ID']),

  },

  async mounted() {
    await this.GET_PPS_TEACHING_LOAD_BY_PPS_ID(this.urlPpsId)

  }
}
</script>

<style scoped>
</style>